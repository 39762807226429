<template>
	<transition :name="`notification-${position}`">
		<div
			v-show="show"
			class="notification review-box"
			:class="[
				{
					mobile: notification.showOnMobile,
					tablet: notification.showOnTablet,
					desktop: notification.showOnDesktop,
				},
				position,
			]"
		>
			<div class="notification-row">
				<div class="box-toggle" @click="() => (show = false)">
					<h4>
						<span>{{ notification.ratingOutOf100 / 10 }}</span> / 10
					</h4>
					<div class="circle">
						<font-awesome-icon icon="fas fa-xmark" size="1x" />
					</div>
				</div>
				<div class="body">
					<div v-html="notification.content" />
					<div class="review-score">
						<div v-if="notification.source" class="review-source" :class="notification.source" />
						<span>
							<font-awesome-icon
								v-for="(icon, index) in ratingToStars(notification.ratingOutOf100)"
								:key="`${notification.ID}-${icon}-${index}`"
								:icon="`fa ${icon}`"
								size="1x"
							/>
						</span>
					</div>
					<a
						v-if="notification.button"
						target="_blank"
						:href="notification.link"
						class="button button-compact"
					>
						{{ notification.button }}
					</a>
				</div>
			</div>
			<div v-show="show" class="background close" />
		</div>
	</transition>
</template>

<script setup>
import getTrigger from '@becurious/conversiontriggers';

const props = defineProps({
	notification: { type: Object, default: () => {} },
});

const show = ref(false);
const position = props.notification.conversionPosition;

onMounted(async () => {
	if (!window.sessionStorage || !props.notification) {
		return;
	}

	if (window.sessionStorage.getItem(`displayed-notification-${props.notification.ID}`)) {
		return;
	}

	const showNotification = await getTrigger(props.notification.trigger);
	show.value = await showNotification(props.notification.ID);

	window.sessionStorage.setItem(`displayed-notification-${props.notification.ID}`, 'true');
});

const ratingToStars = (ratingOutOf100) => {
	const rating = Math.round(ratingOutOf100 / 10);
	const fullStars = parseInt(rating / 2, 10);
	const result = Array(fullStars).fill('fa-star');

	if ((rating / 2) % 1 !== 0) {
		result.push('fa-star-half');
	}

	return result;
};
</script>

<style lang="scss" scoped>
.review-box {
	max-width: 350px;
	transition: all 0.3s ease-in;
	box-shadow: 0 0 4px 1px rgb(0 0 0 / 15%);

	.review-score {
		color: #f67c01;
		margin: 5px 0 10px;

		img {
			max-width: 22px;
		}
	}

	.body {
		background: #fff;
		padding: 5px 20px 20px;

		:deep(p) {
			margin-top: 0;
		}
	}

	.box-toggle {
		cursor: pointer;
		background-color: #fff;
		padding: 15px 20px;
		color: #333;

		.circle {
			position: absolute;
			right: 15px;
			top: -15px;
			background-color: #fff;
			box-shadow: 0 0 4px 1px rgb(0 0 0 / 15%);
			color: #000;
			height: 30px;
			vertical-align: middle;
			width: 30px;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 13px;
		}

		h4 {
			letter-spacing: 0;
			font-size: 29px;

			span {
				font-size: 41px;
				letter-spacing: 1px;
			}
		}
	}
}

.review-source {
	width: 100px;
	height: 67px;
	background-size: 100px 67px;

	&.tripadvisor {
		background-image: url('~/assets/images/review-logo-tripadvisor.png');
	}

	&.bookingcom {
		background-image: url('~/assets/images/review-logo-bookingcom.png');
	}

	&.zoover {
		background-image: url('~/assets/images/review-logo-zoover.png');
	}

	&.expedia {
		background-image: url('~/assets/images/review-logo-expedia.png');
	}

	&.google {
		background-image: url('~/assets/images/review-logo-google.png');
	}
}

@media (max-width: 480px) {
	.background {
		background: rgb(0 0 0 / 50%);
		position: fixed;
		inset: 0;
		z-index: 1;
		transition: all 0.4s ease;
	}

	.review-box {
		.notification-row {
			display: flex;
			flex-flow: wrap row;
			justify-content: space-between;
			align-items: stretch;
			position: fixed;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			max-width: 900px;
			z-index: 3;
			width: calc(100% - 20px);
			background: #fff;

			.image {
				display: none;
			}

			h4 {
				font-size: 20px;
				pointer-events: none;
			}

			.icon.close {
				display: block !important;
			}
		}
	}

	.review-box:not(.mobile) {
		display: none !important;
	}
}

@media (max-width: 801px) {
	.review-box:not(.tablet) {
		display: none !important;
	}
}

@media (min-width: 1025px) {
	.review-box:not(.desktop) {
		display: none !important;
	}
}
</style>
