<template>
	<div>
		<div class="fix-on-scroll menu-button nav-buttons" @click.prevent="openMenu">
			<font-awesome-icon :icon="`fal fa-bars`" size="1x" />
		</div>
		<book-button class="fix-on-scroll book-button nav-buttons reserve-now rr_opener" :pax="2">
			<font-awesome-icon :icon="`fal fa-calendar-alt`" size="1x" />
		</book-button>
		<nav class="top-nav">
			<div class="row">
				<div class="columns column12">
					<div class="text-nav">
						<nuxt-link :to="localePath('/')" class="logo" />
						<ul class="main-nav">
							<li
								v-for="item in topmenu[locale]"
								:key="item.filename"
								:class="{ hasnumber: showSpecialOfferCount(item.slug) }"
							>
								<nuxt-link :exact="item.slug === 'index'" :to="`${item.filename}`">
									{{ item.header }}
									<span v-if="showSpecialOfferCount(item.slug)" class="total-packages">
										{{ totalPackageCount }}
									</span>
								</nuxt-link>
								<submenu
									v-if="item.submenu.length"
									:subitems="item.submenu"
									@hide-mobile-nav="hideMenu"
								/>
							</li>

							<li v-if="page" class="language-navigation" @click="toggleLanguageMenu">
								<div class="lang-select">
									<font-awesome-icon :icon="`fal fa-globe`" size="1x" />
									<span>{{ locale }}</span>
									<font-awesome-icon :icon="`far fa-chevron-down`" size="1x" />
									<div class="lang-wrapper" :class="{ active: showLanguageMenu }">
										<nuxt-link
											v-for="item in page.langmenu"
											:key="`${item.filename}-${item.language}`"
											class="flag"
											:class="item.language"
											:to="`${item.filename}`"
											@click="hideMenu"
										>
											{{ $t(`${item.language}Short`) }}
										</nuxt-link>
									</div>
								</div>
							</li>
						</ul>
					</div>
					<div class="fullscreen-nav" :class="{ active: showMenu }">
						<div class="menu-image">
							<picture>
								<source :srcset="defaults[locale].website.introductionImageWebp" type="image/webp" />
								<source :srcset="defaults[locale].website.introductionImage" />
								<img
									class="object-fit"
									:src="defaults[locale].website.introductionImage"
									loading="lazy"
									alt=""
								/>
							</picture>
						</div>
						<div class="menu-section">
							<div class="menu-close" @click="hideMenu">
								<font-awesome-icon :icon="`fal fa-times`" size="1x" />
							</div>
							<div class="fullscreen-nav-footer">
								<ul class="main-nav">
									<li
										v-for="item in topmenu[locale]"
										:key="item.filename"
										:class="{ hasnumber: showSpecialOfferCount(item.slug) }"
									>
										<nuxt-link
											:exact="item.slug === 'index'"
											:to="`${item.filename}`"
											@click="hideMenu"
										>
											{{ item.header }}
											<span v-if="showSpecialOfferCount(item.slug)" class="total-packages">
												{{ totalPackageCount }}
											</span>
										</nuxt-link>
										<submenu
											v-if="item.submenu.length"
											:subitems="item.submenu"
											@hide-mobile-nav="hideMenu"
										/>
									</li>
								</ul>
								<div v-if="page" class="right-section">
									<div class="lang-nav">
										<nuxt-link
											v-for="item in page.langmenu"
											:key="`${item.filename}-${item.language}`"
											class="flag"
											:class="item.language"
											:to="`${item.filename}`"
											@click="hideMenu"
										>
											{{ $t(`${item.language}Long`) }}
										</nuxt-link>
									</div>
									<br />
									<social-media :socials="socials" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</nav>
		<header v-if="!(page && page.headingImage)" class="no-slider" />
	</div>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { defaults, socials, fetchDefaults } = useDefaults();
const { locale } = useI18n();

await fetchPage();
await fetchDefaults();

const showMenu = ref(false);
const showLanguageMenu = ref(false);

const { data: topmenu } = await useWebsiteFetch('topmenu');
const { data: packages } = await useWebsiteFetch('packages');

const totalPackageCount = packages.value.length;

const toggleLanguageMenu = () => {
	showLanguageMenu.value = !showLanguageMenu.value;
};

const openMenu = () => {
	showMenu.value = true;
	document.body.classList.add('locked');
};

const hideMenu = () => {
	showMenu.value = false;
	document.body.classList.remove('locked');
};

const showSpecialOfferCount = (slug) =>
	slug === 'packages-index' && defaults.value[locale.value].packages.showSpecialOfferCount;
</script>

<style lang="scss" scoped>
@media (min-width: 961px) {
	.text-nav li:hover :deep(.submenu ul) {
		display: block;
	}
}

header.no-slider {
	background: #a8d3ed;
	width: 100%;
	height: 100px;
	margin: 0 0 60px;
}

.locked .top-nav {
	z-index: 101;
}

.reserve-now svg {
	padding: 18px;
}

.top-nav {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	z-index: 18;
	padding: 20px 0;
	transition: background 0.3s ease-in-out;

	.columns {
		padding: 0 20px;
	}

	.row {
		overflow: visible;
	}

	.logo {
		border: 2px solid #fff;
	}

	.logo,
	.lang-nav {
		float: left;
		z-index: 5;
		position: relative;
	}

	.lang-nav {
		margin: 0 0 25px;

		img {
			width: 16px;
			float: left;
			margin: 2px 5px 0 0;
		}

		a {
			width: 100%;
			float: left;
			color: $primary-text-color;
			text-decoration: none;
			margin: 4px 0;
			font-size: 14px;

			&.active {
				color: $primary-color;
			}
		}
	}
}

.language-navigation:hover .lang-nav-sel,
.language-navigation:hover .fa-globe {
	color: $primary-color;
}

.lang-nav-sel {
	box-shadow: none;
	cursor: pointer;
	width: 30px;
	padding: 0 4px 0 0;
	color: #fff;
	border: none;
	text-transform: uppercase;
	font-size: 12px;
	font-family: sans-serif;
	appearance: none;
	background: url('~/assets/images/dropdown-language.png') right 1px center no-repeat;
	background-size: 7px;
	transition: color 0.3s ease-in-out;

	option {
		color: $primary-text-color;
	}

	&::-ms-expand {
		display: none;
	}
}

.lang-select {
	padding: 1px 15px;
	cursor: pointer;
	position: relative;
	color: #fff;

	> span {
		padding-left: 5px;
		padding-right: 5px;
	}

	svg {
		font-size: 18px;
		margin: 0 5px 0 0;
	}
}

.lang-wrapper {
	display: none;
	position: absolute;
	top: 35px;
	left: 3px;
	right: 3px;
	padding: 10px 9px;
	text-align: center;
	background: $primary-background-color;
	box-shadow: 0 18px 18px rgb(0 0 0 / 10%);
	border-radius: 3px;
	z-index: 90;

	&.active,
	a {
		display: flex;
		flex-flow: column nowrap;
		place-content: space-between center;
		color: #000 !important;
		font-size: 13px;
		gap: 5px;
	}

	a {
		width: 100%;
		flex-direction: row;
	}

	img {
		width: 16px;
		height: 16px;
		margin: 2px 0 0;
	}
}

.language-navigation {
	border-left: 2px solid #fff;
	margin-left: 50px !important;

	.fa-globe {
		color: #fff;
		transition: color 0.3s ease-in-out;
	}
}

.main-nav {
	list-style: none;
	position: absolute;
	top: 15px;
	left: 130px;
	right: 0;
	z-index: 4;
	text-align: center;

	a {
		color: #fff;
		padding: 5px;
		text-decoration: none;

		&.router-link-active {
			color: $primary-color;
		}
	}

	li {
		display: inline-block;
		text-transform: uppercase;
		margin: 0 8px;
		letter-spacing: 1px;
		font-size: calc($body-font-size - 1px);
		font-family: $heading-font-family;

		&.hasnumber {
			position: relative;
			padding-right: 20px;
		}
	}
}

.flag::before {
	content: '';
	width: 16px;
	position: relative;
	top: 1px;
	height: 16px;
	display: inline-block;
}

.fullscreen-nav .flag::before {
	top: 4px;
	margin-right: 5px;
}

.flag.en::before {
	background: url('~/assets/images/en.png') no-repeat;
}

.flag.nl::before {
	background: url('~/assets/images/nl.png') no-repeat;
}

.flag.de::before {
	background: url('~/assets/images/de.png') no-repeat;
}

.flag.fr::before {
	background: url('~/assets/images/fr.png') no-repeat;
}

.flag.es::before {
	background: url('~/assets/images/es.png') no-repeat;
}

.total-packages {
	position: absolute;
	right: -25px;
	top: -2px;
	width: 21px;
	height: 21px;
	line-height: 21px;
	text-align: center;
	color: #fff;
	background: #bc1b35;
	border-radius: 50%;
	padding-left: 3px;
	font-size: $secondary-font-size;
}

.menu-section .total-packages {
	right: -30px;
	top: 3px;
	font-size: $body-font-size;
	width: 23px;
	height: 23px;
	line-height: 23px;
}

.nav-buttons,
.menu-close {
	float: right;
	width: 60px;
	height: 60px;
	line-height: 57px;
	text-align: center;
	cursor: pointer;
	box-shadow: 0 0 5px rgb(0 0 0 / 15%);
	font-size: 22px;
	transition: all 0.4s ease-out;
	background: #a8d3ed;
	margin: 5px 0;
	border: 2px solid #fff;
	color: #fff;

	&:hover {
		border-color: #fff;
		color: #fff;
		transform: scale(1.1);
	}
}

.menu-close {
	margin: 0 -59px 0 35px;
}

.text-nav {
	z-index: 1;
	position: relative;
}

.top-nav.fixed .text-nav > ul,
.top-nav.fixed .text-nav select,
.noslider .top-nav .text-nav select,
.menu-button,
.top-nav.fixed .logo {
	opacity: 0;
}

.menu-button,
.book-button {
	position: fixed;
	top: 16px;
	right: 60px;
	z-index: 19;

	&.fixed {
		opacity: 1;
		z-index: 100;
	}
}

.book-button {
	color: #fff;
	background: $primary-color;
	right: 20px;

	&.fixed {
		z-index: 102;
		top: 90px;
	}
}

.nav-button-title {
	position: absolute;
	right: 100%;
	width: 0;
	opacity: 0;
	color: #000;
	font-size: $secondary-font-size;
}

.nav-buttons:hover .nav-button-title {
	opacity: 1;
	width: auto;
	white-space: nowrap;
	margin-right: 10px;
	transition: all 0.4s ease-out;
}

.menu-button.fixed {
	margin-right: -40px;
}

.fullscreen-nav {
	position: fixed;
	top: 0;
	left: 0;
	background: $body-background-color;
	z-index: 100;
	width: 100%;
	height: 100%;
	opacity: 0;
	transition: all 0.5s ease-in-out;
	transform: translate(0%, 100%) matrix(1, 0, 0, 1, 0, 0);

	&.active {
		transform: matrix(1, 0, 0, 1, 0, 0);
		opacity: 1;
	}

	.menu-image {
		float: left;
		background-size: cover;
		background-position: center center;
		width: 38%;
		height: 100vh;
	}

	.menu-section {
		float: left;
		width: 62%;
		padding: 15px 80px 80px;
		position: relative;
		height: 100vh;

		.lang-nav {
			float: left;
			width: 100%;
		}
	}

	.right-section {
		max-width: 50%;
		position: absolute;
		bottom: 0;
		right: 0;
	}

	.socials > a {
		color: $primary-text-color;
		border-color: #e6e6e6;
	}

	.main-nav {
		text-align: left;
		width: 50%;
		float: left;
		bottom: 0;
		left: 0;
		display: flex;
		flex-flow: wrap column;
		align-items: flex-start;
		justify-content: center;

		li {
			display: block;
			font-size: 34px;
			margin: 13px 0;
			text-transform: none;
		}

		a {
			color: $primary-text-color;
			position: relative;
			padding: 0 0 2px;

			&:hover,
			&.router-link-active {
				color: $primary-color;
			}
		}
	}
}

.fullscreen-nav-footer {
	position: absolute;
	inset: 0 100px 80px 80px;
}

.cta-fullscreen-menu {
	float: left;
	display: block;
}

.main-nav li a {
	position: relative;

	&::after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 2px;
		background-color: $primary-color;
		transform: scaleX(0);
		transform-origin: bottom left;
		transition: transform 0.3s;
	}
}

.main-nav li a:hover::after {
	transform: scaleX(1);
}

a {
	font-weight: 400;
	color: $primary-text-color;

	&.active,
	&:hover {
		color: $primary-color;
	}
}

.mobile-dropdown {
	display: none;
	width: 12px;
	height: 8px;
	background: url('~/assets/images/icon-form-dropdown.png') center center no-repeat;
	margin-bottom: 3px;

	&.active {
		transform: rotate(180deg);
	}
}

:deep(.fullscreen-nav .submenu ul li a) {
	font-size: 18px;
	text-transform: none;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
	.menu-close {
		margin: 0 -53px 0 36px !important;
	}
}

@media (min-width: 1281px) {
	.fullscreen-nav .main-nav li {
		font-size: 40px;
	}

	.menu-section .total-packages {
		top: 8px;
	}
}

@media (max-width: 1140px) {
	.main-nav li {
		margin: 0 8px;
		font-size: 17px;
	}
}

@media (max-width: 960px) {
	.menu-button {
		opacity: 1;
		z-index: 100;
		margin-right: -40px;
	}

	.mobile-dropdown {
		display: inline-block;
	}

	.book-button {
		top: 90px;
		opacity: 1;
		z-index: 100;
	}

	.text-nav .main-nav {
		display: none;
	}
}

@media (max-width: 850px) {
	.fullscreen-nav {
		.menu-image {
			display: none;
		}

		.menu-section {
			width: 100%;
			overflow-y: scroll;
		}
	}

	.menu-close {
		margin: 0 -54px 0 36px;
	}

	.locked .book-button.nav-buttons {
		margin-right: 1px;
	}
}

@media (max-width: 600px) {
	.fullscreen-nav {
		.menu-section {
			padding: 15px 20px 40px;
		}

		.main-nav {
			width: 100%;
			float: left;
			flex-flow: wrap column;
			position: relative;
			top: inherit;
			left: inherit;
			right: inherit;
			bottom: inherit;

			li {
				font-size: 26px;
			}
		}

		.right-section {
			float: left;
			position: relative;
			margin: 40px 0 0;
			max-width: 100%;
		}
	}

	.fullscreen-nav-footer {
		top: inherit;
		left: inherit;
		right: inherit;
		bottom: inherit;
		position: relative;
		float: left;
		width: 100%;
		margin: 100px 0 0;
	}

	.menu-close {
		margin: 0 5px 0 35px;
	}

	.menu-section .total-packages {
		top: -1px;
	}
}
</style>
