<template>
	<a id="to-top" ref="toTop" href="#intro" @click.prevent="scroll()">
		<font-awesome-icon icon="fa-light fa-arrow-up" size="1x" />
	</a>
</template>

<script setup>
const offset = 350;
const toTop = ref(null);
const scrollToEl = ref('#intro');

const handleScroll = () => {
	if (window.pageYOffset >= offset) {
		toTop.value.classList.add('show');
		scrollToEl.value = '#';
	} else {
		toTop.value.classList.remove('show');
		scrollToEl.value = '#intro';
	}
};

const scroll = () => {
	const intro = document.getElementById('intro');
	const offsetTop = intro ? intro.offsetTop : offset;
	const top = scrollToEl.value === '#intro' ? offsetTop : 0;

	window.scroll({ top, behavior: 'smooth' });
};

onBeforeMount(() => {
	window.addEventListener('scroll', handleScroll);
});

onBeforeUnmount(() => {
	window.removeEventListener('scroll', handleScroll);
});
</script>

<style lang="scss" scoped>
#to-top {
	opacity: 0;
	position: fixed;
	top: auto;
	right: 25px;
	bottom: 15px;
	z-index: 10;
	width: 60px;
	height: 60px;
	line-height: 57px;
	text-align: center;
	cursor: pointer;
	box-shadow: 0 0 5px rgb(0 0 0 / 15%);
	font-size: 22px;
	transition: all 0.4s ease-out;
	background-color: $primary-background-color;
}

#to-top.show {
	opacity: 1;
}
</style>
