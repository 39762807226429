<template>
	<transition :name="`notification-${position}`">
		<div v-show="show" class="note">
			<div class="box-toggle" @click="() => (show = false)">
				<div class="circle">
					<font-awesome-icon icon="fas fa-xmark" size="1x" />
				</div>
			</div>
			<div class="note-body">
				<div v-if="note.icon" class="note-icon">
					<font-awesome-icon :icon="`far fa-${note.icon}`" size="1x" />
				</div>
				<div class="note-content">
					<p>{{ note.content }}</p>
					<a
						v-if="note.link && note.button"
						target="_blank"
						:href="note.link"
						class="button button-compact button-extra-compact"
					>
						{{ note.button }}
					</a>
				</div>
			</div>
		</div>
	</transition>
</template>

<script setup>
import getTrigger from '@becurious/conversiontriggers';

const props = defineProps({
	note: { type: Object, default: () => {} },
	trigger: { type: String, default: '' },
	id: { type: String, default: '' },
	position: { type: String, default: 'right' },
});

const show = ref(false);

onMounted(async () => {
	if (!window.sessionStorage || !props.trigger) {
		return;
	}

	if (window.sessionStorage.getItem(`displayed-notification-${props.id}`)) {
		return;
	}

	const showNotification = await getTrigger(props.trigger);
	show.value = await showNotification(props.id);

	window.sessionStorage.setItem(`displayed-notification-${props.id}`, 'true');
});
</script>

<style>
.note-wrapper:nth-child(3) .note.notification-right-enter-active,
.note-wrapper:nth-child(3) .note.notification-left-enter-active {
	transition-delay: 1s;
}

.note-wrapper:nth-child(2) .note.notification-right-enter-active,
.note-wrapper:nth-child(2) .note.notification-left-enter-active {
	transition-delay: 3s;
}

.note-wrapper:nth-child(1) .note.notification-right-enter-active,
.note-wrapper:nth-child(1) .note.notification-left-enter-active {
	transition-delay: 6s;
}
</style>

<style lang="scss" scoped>
.notification-enter-active.left {
	transform: translate(-150%, 0);
}

.notification-leave-active.left {
	transform: translate(0, calc(-100% + 15px));
}

.notification-enter-to.left,
.notification-leave-to.left {
	transform: translate(-150%, 0);
}

.notification-enter-active.right {
	transform: translate(150%, 0);
}

.notification-leave-active.right {
	transform: translate(0, calc(100% + 15px));
}

.notification-enter-to.right,
.notification-leave-to.right {
	transform: translate(150%, 0);
}

.note {
	width: 100%;
	z-index: 88888;
	transition: all 0.3s ease;
	margin-bottom: 22px;
	box-shadow: 0 0 4px 1px rgb(0 0 0 / 15%);
	font-size: 14px;
	position: relative;
	padding: 7px 30px 7px 20px;
	background: #46789c;
	color: #fff;

	.note-body {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;

		.note-icon {
			min-width: 35px;
			font-size: 27px;
			text-align: center;
			margin-right: 20px;
		}
	}

	:deep(h1),
	:deep(h2),
	:deep(h3),
	:deep(h4) {
		font-family: proxima-nova, sans-serif;
		font-size: 14px;
		font-weight: bold;
	}

	:deep(p) {
		margin: 5px 0;
	}

	.button {
		color: #46789c;
		background-color: #fff;
	}

	.circle {
		cursor: pointer;
		position: absolute;
		right: 15px;
		top: -15px;
		background-color: #fff;
		color: #000;
		height: 30px;
		vertical-align: middle;
		width: 30px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 13px;
	}
}
</style>
